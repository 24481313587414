<form #questionTest="ngForm" novalidate>
  <div *ngFor="let ques of allQuestions; let idx = index">

    <div class="container-grid quiz-wrap-about">
      <h2 class="h2-about">{{ques.question}}</h2>
      <label class="radio quiz-alt">
        <input type="radio" name="{{ques.id}}" value="a" [(ngModel)]="ques.selected"><span>{{ques.a}}</span>
      </label>
      <label class="radio quiz-alt">
        <input type="radio" name="{{ques.id}}" value="b" [(ngModel)]="ques.selected"><span>{{ques.b}}</span>
      </label>
      <label class="radio quiz-alt">
        <input type="radio" name="{{ques.id}}" value="c" [(ngModel)]="ques.selected"><span>{{ques.c}}</span>
      </label>
      <label class="radio quiz-alt">
        <input type="radio" name="{{ques.id}}" value="d" [(ngModel)]="ques.selected"><span>{{ques.d}}</span>
      </label>
    </div>

  </div>
</form>
<div class="row">
  <div class="col-12" style="justify-content: left;display: flex;">
    <!-- <button type="button" class="btn btn-default" (click)="HomePage()" style="margin-right: 30px;">Avbryt</button> -->
    <button type="button" class="btn btn-quiz" (click)="submitTest()">Svara</button>
  </div>
</div>
<div bsModal #submitModal="bs-modal" class="modal fade" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-left" >
        <div class="h2-quiz">Totalt antal frågor: {{allQuestions.length}} </div>
        <div class="h2-quiz">
          Svar totalt: {{totalAnswered}}
        </div>
        <div class="h2-quiz">
          Rätt svar: {{rightAnswer}}
        </div>
        <div class="quiz_margin">
          <button type="button" class="btn btn-quiz" (click)="checkAnswers()" style="margin-right: 17px;">Se svaren</button>

        <button type="button" class="btn btn-quiz-cancel" (click)="submitModal.hide()" style="margin-left: 17px;">Stäng</button></div>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<div bsModal #addQuestionModal="bs-modal" class="modal fade" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body text-center" style="color: #196f77;">
        <form #questionForm="ngForm"  novalidate>
          <div class="row">
            <div class="col-sm-12 col-12 col-lg-12">
              <div class="form-group">
                <label for="question" class="label-font"> Lägg till fråga</label>
                <span style="color:red;" [hidden]="!(question?.invalid)">*</span>
                <input type="text" class="form-control" name="question" placeholder="Enter Question" [(ngModel)]="questionObj.question" [ngClass]="{
                      'is-invalid': question?.invalid && (question?.dirty ||question?.touched),
                      'is-valid': question?.valid && (question?.dirty || question?.touched)
                  }" #question="ngModel" required>
                <div class="invalid-feedback" *ngIf="question.errors && (question.dirty || question.touched)">
                  <p *ngIf="question.errors.required">Fyll i Beskrivning</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-12 col-lg-6">
              <div class="form-group">
                <label for="a">Option a.</label>
                <span style="color:red;" [hidden]="!(a?.invalid)">*</span>
                <input type="text" class="form-control" name="a" placeholder="Enter First Option" [(ngModel)]="questionObj.a" [ngClass]="{
                              'is-invalid': a?.invalid && (a?.dirty ||a?.touched),
                              'is-valid': a?.valid && (a?.dirty || a?.touched)
                          }" #a="ngModel" required>
                <div class="invalid-feedback" *ngIf="a.errors && (a.dirty || a.touched)">
                  <p *ngIf="a.errors.required">Du måste fylla i något alternativ</p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-lg-6">
              <div class="form-group">
                <label for="b">Option b.</label>
                <span style="color:red;" [hidden]="!(b?.invalid)">*</span>
                <input type="text" class="form-control" name="b" placeholder="Enter Second Option" [(ngModel)]="questionObj.b" [ngClass]="{
                          'is-invalid': b?.invalid && (b?.dirty ||b?.touched),
                          'is-valid': b?.valid && (b?.dirty || b?.touched)
                      }" #b="ngModel" required>
                <div class="invalid-feedback" *ngIf="b.errors && (b.dirty || b.touched)">
                  <p *ngIf="b.errors.required">Du måste fylla i något alternativ</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-12 col-lg-6">
              <div class="form-group">
                <label for="c">Option c.</label>
                <span style="color:red;" [hidden]="!(c?.invalid)">*</span>
                <input type="text" class="form-control" name="c" placeholder="Enter Third Option" [(ngModel)]="questionObj.c" [ngClass]="{
                                'is-invalid': c?.invalid && (c?.dirty ||c?.touched),
                                'is-valid': c?.valid && (c?.dirty || c?.touched)
                            }" #c="ngModel" required>
                <div class="invalid-feedback" *ngIf="c.errors && (c.dirty || c.touched)">
                  <p *ngIf="c.errors.required">Du måste fylla i något alternativ</p>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-12 col-lg-6">
              <div class="form-group">
                <label for="b">Option d.</label>
                <span style="color:red;" [hidden]="!(d?.invalid)">*</span>
                <input type="text" class="form-control" name="d" placeholder="Enter fourth Option" [(ngModel)]="questionObj.d" [ngClass]="{
                            'is-invalid': d?.invalid && (d?.dirty ||d?.touched),
                            'is-valid': d?.valid && (d?.dirty || d?.touched)
                        }" #d="ngModel" required>
                <div class="invalid-feedback" *ngIf="d.errors && (d.dirty || d.touched)">
                  <p *ngIf="d.errors.required">Du måste fylla i något alternativ</p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-md-12 label-font">Välj rätt svar
              <span style="color:red;" [hidden]="!(answer?.invalid)">*</span>
            </label>
            <div class="col-md-12">
              <div class="form-check form-check-inline mr-2">
                <input class="form-check-input" type="radio" name="answer" value="a" [(ngModel)]="questionObj.answer" #answer="ngModel" required>
                <label class="form-check-label">a.</label>
              </div>
              <div class="form-check form-check-inline mr-2">
                <input class="form-check-input" type="radio" name="answer" value="b" [(ngModel)]="questionObj.answer" #answer="ngModel" required>
                <label class="form-check-label">b.</label>
              </div>
              <div class="form-check form-check-inline mr-2">
                <input class="form-check-input" type="radio" name="answer" value="c" [(ngModel)]="questionObj.answer" #answer="ngModel" required>
                <label class="form-check-label">c.</label>
              </div>
              <div class="form-check form-check-inline mr-2">
                <input class="form-check-input" type="radio" name="answer" value="d" [(ngModel)]="questionObj.answer" #answer="ngModel" required>
                <label class="form-check-label">d.</label>
              </div>
            </div>
          </div>
        </form>
        <span>
          <button type="button" class="btn btn-primary" (click)="submitAddQuestion()" [disabled]="questionForm.invalid" [class.cursor-restriction]="questionForm.invalid"
            style="margin-right: 17px;">Lägg till en frånga</button>
        </span>
        <button type="button" class="btn btn-default" (click)="addQuestionModal.hide()">Stäng</button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
<div bsModal #answerModal="bs-modal" class="modal fade" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-body text-left" >
        <div *ngFor="let ques of allQuestions" [style.color]="!ques.selected? '#000' : ques.selected == ques.answer ? 'green': 'red'">
          <div class="h2-quiz">{{ques.question}}</div>
          <div class="p-standard-quiz" >Ditt svar: {{ques[ques.selected]}}</div>
          <div class="p-standard-quiz">Rätt svar: {{ques.answer}}) {{ques[ques.answer]}}</div>
        <hr>
        </div>
        <button type="button" class="btn btn-quiz-cancel" (click)="answerModal.hide()" style="margin-left: 17px;">Stäng</button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
