<div class="container-grid pagedivider-up bluegreen-beige"></div>
  <div class="container-grid guide1-sektion1">
    <div class="container-grid container-23">
      <h1 class="h1-intro-guide" [innerHtml]="htmlPageData[0]?.acf.rubrik"></h1>
      <p class="paragraph ingress-guide" [innerHtml]="htmlPageData[0]?.acf.content"></p>
    </div>
  </div>
  <div class="container-grid guide1-sekktion4">
    <div class="container-grid container-5 guide-3x3">
      <h1 class="h1-overrubrik-guide white" [innerHtml]="htmlPageData[0]?.acf.rubrik_item1"></h1>
      <h1 class="bigbig big white" [innerHtml]="htmlPageData[0]?.acf.rubrik_item2"></h1>
      <div class="container-grid two-col-tl card-wrap-w guide-3x3">
        <a href="{{htmlPageData[0]?.acf.link_item2}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item2">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item3}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item3">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item4}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item4">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
      </div>
      <div class="container-grid two-col-tl card-wrap-w guide-3x3 sek2and3-no-margin-up">
        <a routerLink="{{htmlPageData[0]?.acf.link_item5}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item5">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item6}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item6">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item7}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item7">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
      </div>
      <div class="container-grid two-col-tl card-wrap-w guide-3x3 sek2and3-no-margin-up">
        <a routerLink="{{htmlPageData[0]?.acf.link_item8}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item8">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item9}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item9">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item10}}" class="link-container link-container-1 card-guide-w">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item10">
            </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
      </div>
    </div>
  </div>
