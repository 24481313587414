<div class="container-grid header-bgr">
  <div class="container-grid site-title-logo">
    <a href="/" title="guidepublikutveckling.se" class="sitelogo_link"><h1 class="heading-1 site-title">Guide i publikutveckling </h1></a>
    <div class="container-grid vgr-logo"></div>
  </div>
  <nav class="container-grid navbar navbar-dark navbar-expand-md nav-tl">
    <div class="container-grid nav-title-tl">
      <h6 class="heading-2">HEM</h6>
    </div>
    <a class="glyph navbar-toggler" href="#" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <i class="coffeecup-icons-menu7"></i>
    </a>
    <nav class="container-grid collapse navbar-collapse" id="navbarNav">
      <ul class="list-container navbar-nav" *ngFor="let itm of mainNavData" >

        <li class="list-item-container nav-item ">
          <a class="link-text nav-link nav-link-tl" routerLink="{{itm.acf.link}}" routerLinkActive="active" >{{itm.acf.linkname}}</a>
        </li>

      </ul>
    </nav>
  </nav>
</div>

