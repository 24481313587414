export class QuestionClass {
    "id" ?: number;
    "question": string;
    "a": string;
    "b": string;
    "c": string;
    "d": string;
    "answer": string;
    "selected" ?: string;

}
