<app-scroll-to-top></app-scroll-to-top>
<footer class="container-grid footer">
  <div class="container-grid worksheets-footer">
      <a routerLink="/main/arbetsblad" class="link-container link-container-8" title="Arbetsblad">
      <p class="paragraph p-standard-about link-text-about">Alla arbetsblad finns här:<br></p>
      <div class="container-grid container-6">
        <div class="rule rule-1">
          <hr>
        </div>
        <div class="container-grid container-7"></div>
      </div>
    </a>
  </div>
  <div class="container-grid container-8"><a href="mailto:info@guidepublikutveckling.se" class="link-container link-container-3">
      <p class="paragraph p-std white footer">Kontakt<br>Johanna Hagerius<br>konsulent publikutveckling</p>
    </a>
    <div class="container-grid container-10"></div>
    <div class="container-grid container-11">
      <a routerLink="/main/arbetsmaterial" class="link-container link-container-2 footer" title="Referenser">
        <p class="paragraph p-std white">Referenser</p>
      </a>
      <a href="https://www.vgregion.se/f/kulturforvaltningen/samhallsutveckling/arrangorsutveckling/publikutveckling---republik/" class="link-container link-container-2 footer" rel="noreferrer" target="_blank">
        <p class="paragraph p-std white ">Till RePubliks hemsida</p>
      </a>
      <a routerLink="/main/tillganglighetsredogorelse" class="link-container link-container-2 footer" title="Tillgänglighetsredogörelse">
        <p class="paragraph p-std white footer">Tillgänglighetsredogörelse</p>
      </a>
    </div>
  </div>
  <div class="container-grid container-9 text-left"></div>
 <a href="https://github.com/andreas-barnensbibliotek/guidepublikutveckling_Angular/blob/main/README.md" rel="noreferrer" class="versionlink" target="_blank">{{devversion}}</a>
</footer>
