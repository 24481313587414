<div class="container-grid container-1" role="tablist" aria-multiselectable="true" id="accordion">
  <div class="card container-grid">
    <div class="container-grid card-header card-header-tl" id="headingOne" role="tab">
      <a class="link-text text-link-1" href="#collapseOne" data-toggle="collapse" aria-expanded="true" aria-controls="collapseOne">Visa/Dölj</a>
    </div>
    <div class="collapse show container-grid" id="collapseOne" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-block container-grid">
        <div class="container-grid guide-row1">
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="var-befinner-ni-er-nu" title="Var befinner ni er nu?">Var befinner ni<br>er nu?</a></div>
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="vart-vill-ni-na" title="Vart vill ni nå?">Vart vill ni nå?</a></div>
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="vilka-vill-ni-ha-ombord" title="Vilka vill ni ha ombord?">Vilka vill ni ha<br>ombord?</a></div>
        </div>
        <div class="container-grid guide-row1 guide-row2">
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="hur-ska-ni-ta-er-dit" title="Hur ska ni ta er dit?">Hur ska ni ta<br>er dit?</a></div>
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="hur-vet-ni-att-ni-kommit-fram" title="Hur vet ni att ni kommit fram?">Hur vet ni att ni<br>kommit fram?<br></a></div>
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="vilka-verktyg-behover-ni" title="Vilka verktyg behöver ni?">Vilka verktyg<br>behöver ni?</a></div>
        </div>
        <div class="container-grid guide-row1 guide-row3">
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="vad-behover-ni-pa-farden" title="Vad behöver ni på färden?">Vad behöver ni<br>på färden?</a></div>
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="var-det-vart-det" title="Var det värt det?">Var det värt det?</a></div>
          <div class="container-grid container-2 btn-bgr-tl" routerLinkActive="active-guide"><a class="link-button btn btn-text-tl" routerLink="vilket-ar-nasta-steg" title="Vilket är nästa steg?">Vilket är<br>nästa steg?</a></div>
        </div>
      </div>
    </div>
  </div>
</div>
