<div class="container-grid con-h1-dekor">
  <h1 class="h1-dekor" [innerHtml]="htmlPageData[0]?.acf.rubrik"></h1>
</div>
  <div class="container-grid con-close">
    <a (click)="goBack()" class="link-container link-container-6" title="Tillbaka"></a>
  </div>
  <div class="container-grid container-21">
    <h1 class="h1-about" [innerHtml]="htmlPageData[0]?.acf.rubrik_item1"></h1>
    <p class="paragraph ingress-about" [innerHtml]="htmlPageData[0]?.acf.content_item1">
    </p>
    <app-quiz></app-quiz>

  </div>
  <div class="container-grid container-21" [innerHtml]="htmlPageData[0]?.acf.content_item2">

  </div>
