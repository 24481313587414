<div class="container-grid con-h1-dekor">
  <h1 class="h1-dekor" [innerHtml]="htmlPageData[0]?.acf.rubrik"></h1>
</div>
<div class="container-grid con-close" style="z-index: 9999!important; " >

  <a (click)="goBack()"  data-placement="top" class="link-container link-container-6" style="z-index: 9999!important;" title="Tillbaka"></a>
</div>
<div class="container-grid container-21">
    <div appConvertA [ngClass]="{'placeholderblock' : placeholderblock}" [innerHtml]="htmlPageData[0]?.acf.content">

      <div class="loader"></div>
    </div>
  <app-registerform></app-registerform>
</div>
