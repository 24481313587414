<div class="container-grid container-1" role="tablist" aria-multiselectable="true" id="accordion">
  <div class="card container-grid">
    <div class="container-grid card-header card-header-tl" id="headingOne" role="tab"></div>
  </div>
</div>


<div class="container-grid pagedivider-up bluegreen-beige vad-ar"></div>
<div class="container-grid sektion2-tl-1 vad-pub-1" [innerHtml]="htmlPageData[0]?.acf.content">
</div>
<div class="container-grid vad-pub beige-1 container-4-1">
  <h2 class="h2-bold-1" [innerHtml]="htmlPageData[0]?.acf.rubrik"></h2>

  <div class="container-grid container-5-1">
    <div class="container-grid card-wrap-tl no1">
      <div class="container-grid container-16" [innerHtml]="htmlPageData[0]?.acf.content_item1">
      </div>
      <a routerLink="{{htmlPageData[0]?.acf.link_item1}}" class="link-container link-container-4 vad" title="{{htmlPageData[0]?.acf.rubrik_item1}}">
        <p class="paragraph p-standard-bold no-space-lower white" [innerHtml]="htmlPageData[0]?.acf.rubrik_item1"></p>
        <div class="container-grid container-6-1 vad-ar">
          <div class="rule rule-1-1">
            <hr>
          </div>
          <div class="container-grid container-7-1"></div>
        </div>
      </a>
    </div>
    <div class="container-grid card-wrap-tl no2">
      <div class="container-grid container-16" [innerHtml]="htmlPageData[0]?.acf.content_item2">
      </div>
      <a routerLink="{{htmlPageData[0]?.acf.link_item2}}" class="link-container link-container-4 vad" title="{{htmlPageData[0]?.acf.rubrik_item2}}">
        <p class="paragraph p-standard-bold no-space-lower white" [innerHtml]="htmlPageData[0]?.acf.rubrik_item2"></p>
        <div class="container-grid container-6-1 vad-ar">
          <div class="rule rule-1-1">
            <hr>
          </div>
          <div class="container-grid container-7-1"></div>
        </div>
      </a>
    </div>
  </div>
  <div class="container-grid container-5-1">
    <div class="container-grid card-wrap-tl no3">
      <div class="container-grid container-16" [innerHtml]="htmlPageData[0]?.acf.content_item3">
        </div>
      <a routerLink="{{htmlPageData[0]?.acf.link_item3}}" class="link-container link-container-4 vad" title="{{htmlPageData[0]?.acf.rubrik_item3}}">
        <p class="paragraph p-standard-bold no-space-lower white"  [innerHtml]="htmlPageData[0]?.acf.rubrik_item3"><br> </p>
        <div class="container-grid container-6-1 vad-ar">
          <div class="rule rule-1-1">
            <hr>
          </div>
          <div class="container-grid container-7-1"></div>
        </div>
      </a>
    </div>
    <div class="container-grid card-wrap-tl no4">
      <div class="container-grid container-16" [innerHtml]="htmlPageData[0]?.acf.content_item4">
      </div>
      <a routerLink="{{htmlPageData[0]?.acf.link_item4}}" class="link-container link-container-4 vad" title="{{htmlPageData[0]?.acf.rubrik_item4}}">
        <p class="paragraph p-standard-bold no-space-lower white" [innerHtml]="htmlPageData[0]?.acf.rubrik_item4"></p>
        <div class="container-grid container-6-1 vad-ar">
          <div class="rule rule-1-1">
            <hr>
          </div>
          <div class="container-grid container-7-1"></div>
        </div>
      </a>
    </div>
  </div>
  <div class="container-grid container-5-1">
    <div class="container-grid card-wrap-tl no5">
      <div class="container-grid container-16" [innerHtml]="htmlPageData[0]?.acf.content_item5">
      </div>
      <a routerLink="{{htmlPageData[0]?.acf.link_item5}}" class="link-container link-container-4 vad" title="{{htmlPageData[0]?.acf.rubrik_item5}}">
        <p class="paragraph p-standard-bold no-space-lower white" [innerHtml]="htmlPageData[0]?.acf.rubrik_item5"></p>
        <div class="container-grid container-6-1 vad-ar">
          <div class="rule rule-1-1">
            <hr>
          </div>
          <div class="container-grid container-7-1"></div>
        </div>
      </a>
    </div>
    <div class="container-grid card-wrap-tl no6">
      <div class="container-grid container-16" [innerHtml]="htmlPageData[0]?.acf.content_item6">
      </div>
      <a routerLink="{{htmlPageData[0]?.acf.link_item6}}" class="link-container link-container-4 vad" title="{{htmlPageData[0]?.acf.rubrik_item6}}">
        <p class="paragraph p-standard-bold no-space-lower white" [innerHtml]="htmlPageData[0]?.acf.rubrik_item6"></p>
        <div class="container-grid container-6-1 vad-ar">
          <div class="rule rule-1-1">
            <hr>
          </div>
          <div class="container-grid container-7-1"></div>
        </div>
      </a>
    </div>
  </div>
</div>
<div class="container-grid container-17">
  <a href="{{htmlPageData[0]?.acf.link_item7}}" class="link-container link-container-5" target="_blank" title="{{htmlPageData[0]?.acf.rubrik_item7}}">
    <p class="paragraph ingress no-space" [innerHtml]="htmlPageData[0]?.acf.rubrik_item7"></p>
    <div class="container-grid container-6-1 pilb">
      <div class="rule rule-1-1">
        <hr>
      </div>
      <div class="container-grid container-7-1"></div>
    </div>
  </a></div>
<div class="container-grid pagedivider-up bluegreen-beige vad-ar bottom burg"></div>
<div class="container-grid container-18">
  <div class="container-grid container-17">
    <a routerLink="{{htmlPageData[0]?.acf.link_item8}}" class="link-container link-container-5" title="{{htmlPageData[0]?.acf.rubrik_item8}}">
      <h1 class="vad-pub big-1 bigbig-1 white-1 bottom" [innerHtml]="htmlPageData[0]?.acf.rubrik_item8"><br></h1>
      <p class="paragraph ingress white no-space" [innerHtml]="htmlPageData[0]?.acf.content_item8"></p>
      <div class="container-grid container-6-1 pilb">
        <div class="rule rule-1-1 white">
          <hr>
        </div>
        <div class="container-grid container-7-1 white"></div>
      </div>
    </a></div>
</div>
