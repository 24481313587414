<div class="container-grid container-1" role="tablist" aria-multiselectable="true" id="accordion">
  <div class="card container-grid">
    <div class="container-grid card-header card-header-tl" id="headingOne" role="tab"></div>
  </div>
</div>
<div class="container-grid pagedivider-up bluegreen-beige"></div>
  <div class="container-grid sektion2-tl">
    <div class="container-grid sektion2-content">
      <article class="container-grid sektion2-text" [innerHtml]="htmlPageData[0]?.acf.content">

      </article>
      <div class="container-grid container-3" [innerHtml]="htmlPageData[0]?.acf.rubrik" >
      </div>
    </div>
  </div>
  <div class="container-grid pagedivider-up beige-beige"></div>
  <div class="container-grid container-4 beige">
    <div class="container-grid container-5">
      <h1 class="bigbig big" [innerHtml]="htmlPageData[0]?.acf.rubrik_item1"></h1>
      <div class="container-grid two-col-tl">
        <a routerLink="{{htmlPageData[0]?.acf.link_item1}}" class="link-container link-container-1 two-col" title="Vad är det?">
          <div class="container-grid" [innerHtml]="htmlPageData[0]?.acf.content_item1">
          </div>
          <div class="container-grid container-6">
            <div class="rule rule-1">
              <hr>
            </div>
            <div class="container-grid container-7"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item2}}" class="link-container link-container-1 two-col" title="{{htmlPageData[0]?.acf.rubrik_item2}}">
          <div class="container-grid" [innerHtml]="htmlPageData[0]?.acf.content_item2">
          </div>
          <div class="container-grid container-6">
            <div class="rule rule-1">
              <hr>
            </div>
            <div class="container-grid container-7"></div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="container-grid pagedivider-up beige"></div>
  <div class="container-grid container-4 sek3-tl">
    <div class="container-grid container-5">
      <h1 class="bigbig white bigbigbig">Guiden<br></h1>
      <div class="container-grid two-col-tl">
        <a routerLink="{{htmlPageData[0]?.acf.link_item3}}" class="link-container link-container-1 two-col" title="{{htmlPageData[0]?.acf.rubrik_item3}}">
          <div class="container-grid" [innerHtml]="htmlPageData[0]?.acf.content_item3">
          </div>
          <div class="container-grid container-6">
            <div class="rule rule-1">
              <hr>
            </div>
            <div class="container-grid container-7"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item4}}" class="link-container link-container-1 two-col" title="{{htmlPageData[0]?.acf.rubrik_item4}}">
          <div class="container-grid" [innerHtml]="htmlPageData[0]?.acf.content_item4">
          </div>
          <div class="container-grid container-6">
            <div class="rule rule-1">
              <hr>
            </div>
            <div class="container-grid container-7"></div>
          </div>
        </a>
        </div>
    </div>
  </div>

  <div class="container-grid citat-tl">
    <div class="container-grid container-12">
      <div class="responsive-picture picture-1">
        <picture>
          <img alt="Placeholder Picture" width="2720" height="1904" src="./../../../assets/ad/Citat-lila.png" loading="lazy">
        </picture>
      </div>
      <div class="container-grid container-13" [innerHtml]="htmlPageData[0]?.acf.content_item6">
      </div>
    </div>
  </div>
  <div class="container-grid container-4 grey">
    <div class="container-grid container-5">
      <h1 class="bigbig big white">Arbetsmaterial <br></h1>
      <div class="container-grid two-col-tl">
        <a routerLink="{{htmlPageData[0]?.acf.link_item7}}" class="link-container link-container-1 three-col"  title="{{htmlPageData[0]?.acf.rubrik_item7}}">
          <div class="container-grid" [innerHtml]="htmlPageData[0]?.acf.content_item7">
          </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item8}}" class="link-container link-container-1 three-col" title="{{htmlPageData[0]?.acf.rubrik_item8}}">
          <div class="container-grid" [innerHtml]="htmlPageData[0]?.acf.content_item8">
          </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
        <a routerLink="{{htmlPageData[0]?.acf.link_item9}}" class="link-container link-container-1 three-col" title="{{htmlPageData[0]?.acf.rubrik_item9}}">
          <div class="container-grid"  [innerHtml]="htmlPageData[0]?.acf.content_item9">
          </div>
          <div class="container-grid container-6">
            <div class="rule rule-1 white">
              <hr>
            </div>
            <div class="container-grid container-7 white"></div>
          </div>
        </a>
      </div>
    </div>
  </div>
