<div class="container-grid pagedivider-up bluegreen-beige"></div>
<div class="container-grid guide1-sektion1">
  <div class="container-grid container-23">
    <h1 class="h1-intro-guide" [innerHtml]="htmlPageData[0]?.acf.rubrik"></h1>
    <p class="paragraph ingress-guide" [innerHtml]="htmlPageData[0]?.acf.content"></p>
  </div>
</div>
<div class="container-grid guide1-sektion2">
  <div class="container-grid container-5" [innerHtml]="htmlPageData[0]?.acf.content_item1">
  </div>
</div>
