<div class="container-grid pagedivider-up bluegreen-beige"></div>
<div class="container-grid guide1-sektion1">
  <div class="container-grid container-23">
    <h1 class="h1-intro-guide" [innerHtml]="htmlPageData[0]?.acf.rubrik"></h1>
    <p class="paragraph ingress-guide" [innerHtml]="htmlPageData[0]?.acf.content"></p>
  </div>
</div>
<div class="container-grid guide1-sektion2">
  <div class="container-grid container-5">
    <h1 class="h1-overrubrik-guide" [innerHtml]="htmlPageData[0]?.acf.rubrik_item1"></h1>
    <h1 class="bigbig" [innerHtml]="htmlPageData[0]?.acf.rubrik_item2"></h1>
    <div class="container-grid two-col-tl card-wrap" [innerHtml]="htmlPageData[0]?.acf.content_item1">
    </div>
    <div class="container-grid container-26 m0" [innerHtml]="htmlPageData[0]?.acf.content_item2">
    </div>
    <div class="container-grid container-26" [innerHtml]="htmlPageData[0]?.acf.content_item3">
    </div>
    <p class="paragraph p-std var-det-fix" [innerHtml]="htmlPageData[0]?.acf.content_item4"></p>
    <a href="{{htmlPageData[0]?.acf.link_item5}}" [innerHtml]="htmlPageData[0]?.acf.content_item5" class="link-container link-container-1 two-col card-guide" target="_blank" ref="noreferrer" title="{{htmlPageData[0]?.acf.rubrik_item5}}">
    </a>

  </div>
</div>
